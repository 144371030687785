/* Item List, Vue Component */
<template>
  <v-card id="item-list">
    <v-card-title v-if="title">{{title}}</v-card-title>

    <v-card-text v-if="showSearch" class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field>

        <OrderLookup class="mr-4" v-model="filter.OrderId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" />

        <UserLookup class="mr-4" v-model="filter.BookedInBy" :outlined="true" :inverted="false" :hide-details="true" :dense="true" />


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.Action`]="{item}">
      <Can I="update" on="CustomerCare">
      <div v-if="StatusAllowsChanges" class="d-flex align-center justify-center">
        <ActionsDropdown :item="item" :actionOptions="actionOptions" />
      </div>
      </Can>
    </template>


		<template #[`item.Heavy`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.Heavy).heavy">{{ booleanIcons(item.Heavy).icon }}</v-icon>
    </template>

		<template #[`item.Dangerous`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.Dangerous).dangerous">{{ booleanIcons(item.Dangerous).icon }}</v-icon>
    </template>

		<template #[`item.Damaged`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.Damaged).variant">{{ booleanIcons(item.Damaged).icon }}</v-icon>
    </template>

		<template #[`item.RefusedDelivery`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.RefusedDelivery).variant">{{ booleanIcons(item.RefusedDelivery).icon }}</v-icon>
    </template>

    <template #[`item.Guesstimated`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.Guesstimated).guesstimated">{{ booleanIcons(item.Guesstimated).icon }}</v-icon>
    </template>

    <template #[`item.DoNotSend`]="{item}">
      <v-icon v-if="item.DoNotSend" size="18" color="error">{{ icons.mdiClose }}</v-icon>
    </template>


    <template #[`item.Dimensions`]="{item}">
      {{ item.Length }}x{{ item.Width }}x{{ item.Height }} mm
    </template>

    <template #[`item.BookedInBy`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'user-view', params: { id: item.BookedInBy } }">
        {{ item.BookedInBy_FullName }}
          </router-link>
    </template>

    </v-data-table>

    <action-confirm-dialog @toggleControl="(x) => toggleDialogSetDoNotSend = x" @confirmed="confirmSetDoNotSend" dialogTitle="Set Do Not Send">
      <template v-slot:information>
        <p>By setting <strong>Do Not Send</strong> the item will be excluded from shipping and pricing calculations.</p>
        <p>If the item already has a label you must reprint all labels for this order.</p>
      </template>
      <template v-slot><span></span></template>
    </action-confirm-dialog>

    <action-confirm-dialog @toggleControl="(x) => toggleDialogUnsetDoNotSend = x" @confirmed="confirmUnsetDoNotSend" dialogTitle="Un-Set Do Not Send">
      <template v-slot:information>
        <p>If the item does not already have a label you must reprint all labels for this order.</p>
      </template>
      <template v-slot><span></span></template>
    </action-confirm-dialog>

    <action-confirm-dialog @toggleControl="(x) => toggleDialogSetDamaged = x" @confirmed="confirmSetDamaged" dialogTitle="Set Damaged">
      <template v-slot:information>
        <p>By setting <strong>Damaged</strong> the item will <strong>not</strong> be excluded from shipping and pricing calculations.</p>
        <p>Please also set <strong>Do Not Send</strong> to exclude from shipping.</p>
      </template>
      <template v-slot><span></span></template>
    </action-confirm-dialog>

    <action-confirm-dialog @toggleControl="(x) => toggleDialogUnsetDamaged = x" @confirmed="confirmUnsetDamaged" dialogTitle="Un-Set Damaged">
      <template v-slot:information>
        <p>Un-setting <strong>Damaged</strong> does not unset <strong>Do Not Send</strong>.</p>
      </template>
      <template v-slot><span></span></template>
    </action-confirm-dialog>

    <action-confirm-dialog @toggleControl="(x) => toggleDialogSetRefused = x" @confirmed="confirmSetRefused" dialogTitle="Set Refused">
      <template v-slot:information>
        <p>By setting <strong>Refused</strong> the item will <strong>not</strong> be excluded from shipping and pricing calculations.</p>
        <p>Please also set <strong>Do Not Send</strong> to exclude from shipping.</p>
      </template>
      <template v-slot><span></span></template>
    </action-confirm-dialog>

    <action-confirm-dialog @toggleControl="(x) => toggleDialogUnsetRefused = x" @confirmed="confirmUnsetRefused" dialogTitle="Un-Set Refused">
      <template v-slot:information>
        <p>Un-setting <strong>Refused</strong> does not unset <strong>Do Not Send</strong>.</p>
      </template>
      <template v-slot><span></span></template>
    </action-confirm-dialog>

    <action-confirm-dialog @toggleControl="(x) => toggleDialogSetHeavy = x" @confirmed="confirmSetHeavy" dialogTitle="Set Heavy">
      <template v-slot:information>
        <p>By setting <strong>Heavy</strong> the order will not be able to match against a standard product and will require bespoke pricing.</p>
      </template>
      <template v-slot><span></span></template>
    </action-confirm-dialog>

    <action-confirm-dialog @toggleControl="(x) => toggleDialogUnsetHeavy = x" @confirmed="confirmUnsetHeavy" dialogTitle="Un-Set Heavy">
      <template v-slot:information>
        <p>Un-setting <strong>Heavy</strong> the order will be able to match against a standard product and will only require bespoke pricing for large items.</p>
      </template>
      <template v-slot><span></span></template>
    </action-confirm-dialog>

    <action-confirm-dialog @toggleControl="(x) => toggleDialogSetDangerous = x" @confirmed="confirmSetDangerous" dialogTitle="Set Dangerous">
      <template v-slot:information>
        <p>By setting <strong>Dangerous</strong> the order will be charged with addition DGN.</p>
      </template>
      <template v-slot><span></span></template>
    </action-confirm-dialog>

    <action-confirm-dialog @toggleControl="(x) => toggleDialogUnsetDangerous = x" @confirmed="confirmUnsetDangerous" dialogTitle="Un-Set Dangerous">
      <template v-slot><span></span></template>
    </action-confirm-dialog>

  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiCancel,
  mdiClose,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiCubeSend,
  mdiTagOffOutline,
  mdiImageBrokenVariant,
  mdiCheckboxBlank,
  mdiFeather,
  mdiWeight,
  mdiBatteryCharging90
} from '@mdi/js'

import { ref, watch, computed, toRefs } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import OrderLookup from '@/views/order/OrderLookup'
import UserLookup from '@/views/user/UserLookup'
import ActionsDropdown from '@/views/helpers/ActionsDropdown'
import ActionConfirmDialog from '@/views/helpers/ActionConfirmDialog'
import { Can } from '@casl/vue'

export default {
  props: {
    OrderId: {
      type: Number,
      required: true
    },
    title: {
      required: false,
      default: "Order Items"
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    OrderStatus: {
      type: String,
      default: ""
    }
  },
  components: {
    OrderLookup,
		UserLookup,
    ActionsDropdown,
    ActionConfirmDialog,
    Can
  },
  emits: ['update'],
  setup(props, { emit }) {

  const { OrderId, title, OrderStatus } = toRefs(props);

  // define cols
  const tableColumns = [
    { text: '', value: 'Action', sortable: false },
		//{ text: 'Order Reference', value: 'OrderId_Reference' },
		{ text: 'Dimensions', value: 'Dimensions', sortable: false },
		// { text: 'Length', value: 'Length' },
		// { text: 'Width', value: 'Width' },
		// { text: 'Height', value: 'Height' },
		{ text: 'Heavy', value: 'Heavy' },
		{ text: 'DGN', value: 'Dangerous' },
		{ text: 'Damaged', value: 'Damaged' },
		{ text: 'Refused Delivery', value: 'RefusedDelivery' },
		{ text: 'Do Not Send', value: 'DoNotSend' },
		{ text: 'Guesstimated', value: 'Guesstimated' },
		{ text: 'Notes', value: 'Notes' },
		{ text: 'Booked In By', value: 'BookedInBy' },
  ];

  // threee params for fetching
  const search = ref({});
  const filter = ref({
    OrderId: (OrderId ? OrderId.value : null)
  });
  const options = ref({
    sortBy: ['Created'],
    sortDesc: [false],
    itemsPerPage: 10
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.Items || []);
  const totalItems = computed(() => store.state.app.ItemsTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
  const filterItems = ref({

  });

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchItems', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });


  const booleanIcons = status => {
    if (status) return { variant: 'error', heavy: 'info', guesstimated: 'success', icon: mdiCheckboxMarkedOutline }
    return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
  }

    const StatusAllowsChanges = ref(false);
    if (["Held", "Ready"].includes(OrderStatus.value)) {
      StatusAllowsChanges.value = true;
    }

    // messy code to get confirmation dialog to work from external handle in list, in actions dropdown
    const toggleDialogSetDoNotSend = ref(() => {});
    const toggleDialogUnsetDoNotSend = ref(() => {});

    const showSend = (item) => {
      return StatusAllowsChanges.value && item.DoNotSend;
    }
    const showDoNotSend = (item) => {
      return StatusAllowsChanges.value && !item.DoNotSend;
    }
    const setDoNotSend = (item) => {
      if (toggleDialogSetDoNotSend.value) {
        toggleDialogSetDoNotSend.value(true, item);
      }
    }
    const unsetDoNotSend = (item) => {
      if (toggleDialogUnsetDoNotSend.value) {
        toggleDialogUnsetDoNotSend.value(true, item);
      }
    }
    const confirmSetDoNotSend = ({comment, userData}) => {
      store.dispatch('app/toggleDoNotSendItem', { id: userData.Id, entity: userData, DoNotSend: true, comment: comment })
      .then(response => {
        emit('update');
      });
    }
    const confirmUnsetDoNotSend = ({comment, userData}) => {
      store.dispatch('app/toggleDoNotSendItem', { id: userData.Id, entity: userData, DoNotSend: false, comment: comment })
      .then(response => {
        emit('update');
      });
    }


    const toggleDialogSetDamaged = ref(() => {});
    const toggleDialogUnsetDamaged = ref(() => {});

    const showDamaged = (item) => {
      return StatusAllowsChanges.value && item.Damaged;
    }
    const showUnsetDamaged = (item) => {
      return StatusAllowsChanges.value && !item.Damaged;
    }
    const setDamaged = (item) => {
      if (toggleDialogSetDamaged.value) {
        toggleDialogSetDamaged.value(true, item);
      }
    }
    const unsetDamaged = (item) => {
      if (toggleDialogUnsetDamaged.value) {
        toggleDialogUnsetDamaged.value(true, item);
      }
    }
    const confirmSetDamaged = ({comment, userData}) => {
      store.dispatch('app/toggleDamagedItem', { id: userData.Id, entity: userData, Damaged: true, comment: comment })
      .then(response => {
        emit('update');
      });
    }
    const confirmUnsetDamaged = ({comment, userData}) => {
      store.dispatch('app/toggleDamagedItem', { id: userData.Id, entity: userData, Damaged: false, comment: comment })
      .then(response => {
        emit('update');
      });
    }

    const toggleDialogSetRefused = ref(() => {});
    const toggleDialogUnsetRefused = ref(() => {});

    const showRefused = (item) => {
      return StatusAllowsChanges.value && item.RefusedDelivery;
    }
    const showUnsetRefused = (item) => {
      return StatusAllowsChanges.value && !item.RefusedDelivery;
    }
    const setRefused = (item) => {
      if (toggleDialogSetRefused.value) {
        toggleDialogSetRefused.value(true, item);
      }
    }
    const unsetRefused = (item) => {
      if (toggleDialogUnsetRefused.value) {
        toggleDialogUnsetRefused.value(true, item);
      }
    }
    const confirmSetRefused = ({comment, userData}) => {
      store.dispatch('app/toggleRefusedItem', { id: userData.Id, entity: userData, Refused: true, comment: comment })
      .then(response => {
        emit('update');
      });
    }
    const confirmUnsetRefused = ({comment, userData}) => {
      store.dispatch('app/toggleRefusedItem', { id: userData.Id, entity: userData, Refused: false, comment: comment })
      .then(response => {
        emit('update');
      });
    }

    const toggleDialogSetHeavy = ref(() => {});
    const toggleDialogUnsetHeavy = ref(() => {});

    const showSetHeavy = (item) => {
      return StatusAllowsChanges.value && !item.Heavy;
    }
    const showUnsetHeavy = (item) => {
      return StatusAllowsChanges.value && item.Heavy;
    }
    const setHeavy = (item) => {
      if (toggleDialogSetHeavy.value) {
        toggleDialogSetHeavy.value(true, item);
      }
    }
    const unsetHeavy = (item) => {
      if (toggleDialogUnsetHeavy.value) {
        toggleDialogUnsetHeavy.value(true, item);
      }
    }
    const confirmSetHeavy = ({comment, userData}) => {
      store.dispatch('app/toggleHeavyItem', { id: userData.Id, entity: userData, Heavy: true, comment: comment })
      .then(response => {
        emit('update');
      });
    }
    const confirmUnsetHeavy = ({comment, userData}) => {
      store.dispatch('app/toggleHeavyItem', { id: userData.Id, entity: userData, Heavy: false, comment: comment })
      .then(response => {
        emit('update');
      });
    }

    const toggleDialogSetDangerous = ref(() => {});
    const toggleDialogUnsetDangerous = ref(() => {});

    const showSetDangerous = (item) => {
      return StatusAllowsChanges.value && !item.Dangerous;
    }
    const showUnsetDangerous = (item) => {
      return StatusAllowsChanges.value && item.Dangerous;
    }
    const setDangerous = (item) => {
      if (toggleDialogSetDangerous.value) {
        toggleDialogSetDangerous.value(true, item);
      }
    }
    const unsetDangerous = (item) => {
      if (toggleDialogUnsetDangerous.value) {
        toggleDialogUnsetDangerous.value(true, item);
      }
    }
    const confirmSetDangerous = ({comment, userData}) => {
      store.dispatch('app/toggleDangerousItem', { id: userData.Id, entity: userData, Dangerous: true, comment: comment })
      .then(response => {
        emit('update');
      });
    }
    const confirmUnsetDangerous = ({comment, userData}) => {
      store.dispatch('app/toggleDangerousItem', { id: userData.Id, entity: userData, Dangerous: false, comment: comment })
      .then(response => {
        emit('update');
      });
    }

    const actionOptions = [
        { title: 'Send', icon: mdiCubeSend, click: unsetDoNotSend, show: showSend },
        { title: 'Do Not Send', icon: mdiTagOffOutline, click: setDoNotSend, show: showDoNotSend },
        { title: 'Set Not Damaged', icon: mdiCheckboxBlank , click: unsetDamaged, show: showDamaged },
        { title: 'Set Damaged', icon: mdiImageBrokenVariant , click: setDamaged, show: showUnsetDamaged },
        { title: 'Set Not Refused', icon: mdiCheckboxBlank , click: unsetRefused, show: showRefused },
        { title: 'Set Refused', icon: mdiCancel  , click: setRefused, show: showUnsetRefused },
        { title: 'Set Not Heavy', icon: mdiFeather, click: unsetHeavy, show: showUnsetHeavy },
        { title: 'Set Heavy', icon: mdiWeight, click: setHeavy, show: showSetHeavy },
        { title: 'Set Not Dangerous', icon: mdiCubeSend, click: unsetDangerous, show: showUnsetDangerous },
        { title: 'Set Dangerous', icon: mdiBatteryCharging90, click: setDangerous, show: showSetDangerous }
      ]

    return {
      StatusAllowsChanges,
      confirmUnsetDamaged,
      confirmSetDamaged,
      confirmUnsetDoNotSend,
      confirmSetDoNotSend,
      confirmUnsetRefused,
      confirmSetRefused,
      confirmUnsetHeavy,
      confirmSetHeavy,
      confirmUnsetDangerous,
      confirmSetDangerous,
      toggleDialogSetDamaged,
      toggleDialogUnsetDamaged,
      toggleDialogUnsetDoNotSend,
      toggleDialogSetDoNotSend,
      toggleDialogSetRefused,
      toggleDialogUnsetRefused,
      toggleDialogSetHeavy,
      toggleDialogUnsetHeavy,
      toggleDialogSetDangerous,
      toggleDialogUnsetDangerous,
      actionOptions,
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      footerProps,
      booleanIcons,
      Validators,
      Display,
      icons: {
        mdiPlus,
        mdiClose,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
